<template>
  <div class="tab-pane active" id="live-stats">
    <div class="card-body">
      <div class="table-responsive">
        <div
          v-if="fetched && !usage.length"
          class="text-center table-placeholder"
        >
          <br />
          <i class="fas fa-tachometer-alt font-size-80"></i>
          <h5 class="card-title m-t-20">Nenhum gasto encontrado</h5>
        </div>

        <data-table
          :data="usage"
          :pages="pages"
          :columns="[
            {
              key: 'creation_date',
              filters: ['date|DD/MM/YYYY'],
              label: 'created_at',
            },
            'protocol',
            {
              key: 'channel',
              class: 'badge badge-primary text-white',
            },
            {
              key: 'open_date',
              filters: ['date|HH:mm:ss'],
              label: 'start_hour',
            },
            {
              key: 'close_date',
              filters: ['date|HH:mm:ss'],
              label: 'end_hour',
            },
            'name',
            'tabulation',
            'evaluation',
          ]"
          @change-page="preFetchUsage"
          v-else-if="fetched">
          <template #actions="item">
            <a href="javascript:void(0)" @click="showMessages(item)"
              ><i class="fas fa-comments"></i
            ></a>
          </template>
        </data-table>

        <!-- <div v-else class="qt-block-ui" /> -->
        <div class="loading min-h-300" v-else>
          <LoadingAnim />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import Tab from '@/directives/Tab';
import UsageService from '@/services/usage.service';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import DataTable from '@/components/rebranding/DataTable.vue';

export default {
  name: 'LiveReport',
  directives: {
    Tab,
  },
  components: {
    DataTable,
    LoadingAnim,
  },
  props: ['dateGte', 'dateLte'],
  data() {
    return {
      fetchTimeout: 0,
      fetched: true,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('01/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      usage: [],
      pages: 0,
      form: {
        // category: 'sms',
        // date: {
        //   gte: moment().format('YYYY-MM-01'),
        //   lte: moment().format('YYYY-MM-DD'),
        // },
      },
    };
  },
  /* computed: {
    dateGte() {
      this.preFetchUsage(1);
      return this.$parent.form.date.gte;
    },
    dateLte() {
      this.preFetchUsage(1);
      return this.$parent.form.date.lte;
    },
  }, */
  watch: {
    dateGte: {
      handler(value) {
        this.preFetchUsage(1);
      },
      deep: true,
    },
    dateLte: {
      handler(value) {
        this.preFetchUsage(1);
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchUsage(1);
  },
  methods: {
    showMessages(room) {
      this.fetchRoom(room.id);
    },
    fetchRoom(roomId) {
      this.currentRoom = null;
      this.$refs['messages-modal'].toggle();
      this.$store.dispatch('fetchOmniRoom', roomId).then(
        (room) => {
          this.currentRoom = room;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    reload() {
      this.fetchUsage(1);
    },
    preFetchUsage(page) {
      clearTimeout(this.fetchTimeout);
      this.fetchTimeout = setTimeout(() => this.fetchUsage(page), 10);
    },
    fetchUsage(page) {
      this.fetched = false;
      // UsageService.getAllUsage({
      //   category: 'sms',
      //   date: {
      //     gte: this.dateGte,
      //     lte: this.dateLte,
      //   },
      // }).then((response) => {
      //   this.usage = response;
      //   this.fetched = true;
      // });
      this.$store.dispatch('fetchOmniRooms', { page, ...this.form }).then((response) => {
        // console.info('fetchOmniRooms', response.data);
        const resp = response.data;
        this.usage = resp.data;
        this.pages = resp.last_page;
      }, (error) => {
        console.error('fetchOmniRooms', error.response.data);
      }).finally(() => {
        this.fetched = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-responsive {
  min-height: 150px;
}
</style>
